import React from 'react';
import { Link } from 'react-scroll'; 
import Image from '../assets/main.PNG';
import { FaGithub, FaYoutube, FaLinkedin, FaWhatsapp, FaPinterest } from 'react-icons/fa';
import { FaMedium } from "react-icons/fa6";
import { SiLeetcode } from "react-icons/si";
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Banner = () => { 
  return (
    <section className='min-h-[85vh] lg-min-h-[74vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg-items-center lg-gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[50px] font-bold leading-[0.8] lg:text-[100px]'>
              DEBADITYA <span>SOM</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className=' text-white mr-4'>I'm a </span>
              <TypeAnimation sequence={[
                'Student',
                2000,
                'Designer',
                2000,
                'Developer',
                2000
              ]}
                speed={50}
                className='text-cyan-300'
                wrapper='span'
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn('up', 0.6)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-8 max-w-lg mx-auto lg:mx-0'>
              CS Undergrad with a specialization on Business Systems and an insatiable desire to contribute to open-source projects.
            </motion.p>
            <div className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0 '>
              <Link to='contact' smooth={true} duration={500}>
                <button className='btn btn-lg'>Contact me</button>
              </Link>
            </div>
            <motion.div
              variants={fadeIn('up', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='flex text-[20px] gap-x-5 max-w-max mx-auto lg:mx-0'>
              <a href='https://github.com/Debaditya-Som'>
                <FaGithub />
              </a>
              <a href='https://www.linkedin.com/in/debaditya-som-877032262/'>
                <FaLinkedin />
              </a>
              <a href='https://www.youtube.com/channel/UCM32f8PDqNdRoK9qkPCepUg'>
                <FaYoutube />
              </a>
              <a href='https://in.pinterest.com/debaditya_som/'>
                <FaPinterest />
              </a>
              <a href='https://medium.com/@debadityasom04'>
                <FaMedium />
              </a>
              <a href='https://medium.com/@debadityasom04'>
                <FaWhatsapp />
              </a>
              <a href='https://leetcode.com/debaditya-som04/'>
                <SiLeetcode />
              </a>
            </motion.div>
          </div>
          <motion.div
            variants={fadeIn('down', 0.5)}
            initial="hidden"
            whileInView={'show'}
            className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px] '>
           <img  src={Image} alt="Main" />
          </motion.div>
        </div>

      </div>
    </section>
  );
};

export default Banner;