import React, { useState } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { motion, AnimatePresence } from "framer-motion";
import { fadeIn } from "../variants";
import ab from "../assets/anushka_bhowmick.jpeg";
import ff from "../assets/ff.jpeg";
import mp from '../assets/mammam_pishi.jpeg';
import as from "../assets/adityada.jpg";
const recommendationsData = [
  {
    id: 1,
    name: "Amrapali Roy",
    position: "Power BI Business Analyst at Tata Consultancy Services",
    comment:
      "What sets Debaditya apart is not just his technical proficiency, but also his strong analytical thinking and innovative approach to problem-solving. He approaches challenges with a blend of creativity and methodical reasoning, resulting in elegant solutions that exceed expectations.",
    image: mp,
  },
  {
    id: 2,
    name: "Anushka Bhowmick",
    position: "MERN Stack Developer",
    comment:
      "Debaditya is a great, hardworking individual and an active open source contributer who is always ready to learn and explore new technologies. The curiosity to learn and grow is indeed commendable.",
    image: ab,
  },
  {
    id: 3,
    name: "Soumyajit Mondal",
    position: "Founder of Resourcio Community",
    comment:
      "Debaditya is a hardworking student. He is always excited to learn new things, engage in new activities, and explore different domains. The level of dedication he exhibits in a particular task is truly remarkable and somewhat uncommon these days. I have known him for a year now, and during this time, he has made significant personal development strides. I am confident that he will continue to shine brightly in his life.",
    image: ff,
  },
  {
    id: 4,
    name: "Aditya Seth",
    position: "Content Architect at Gotogrowth",
    comment:
      "One of the values on our engineering team is “constant improvement,” and I’ve never seen anyone embrace and embody that philosophy like Debaditya. During the past year we’ve worked together, I’ve watched him constantly look for new challenges to tackle, whether it’s getting to the root of a buggy software, writing a script to land a reservation at an always-booked restaurant. He's also always the first to jump in if newer members of the team run into issues. I’ve loved my experience working with Debaditya, and I know that anyone looking to hire or work with him will, too.",
    image: as,
  },
];

const RecommendationCard = ({ name, position, comment, image }) => (
  <div className="w-full max-w-3xl mx-auto my-8">
    <div className="rounded-lg overflow-hidden shadow-md p-8">
      <div className="flex items-center mb-5">
        <div className="bg-gray-200 w-32 h-32 md:w-40 md:h-40 min-w-40 min-h-40 rounded-full overflow-hidden mr-8">
          <img
            src={image}
            alt={name}
            className="w-full h-full object-cover rounded-full aspect-w-1 aspect-h-1"
          />
        </div>
        <div>
          <h3 className="text-2xl font-bold mb-2">{name}</h3>
          <p className="text-lg font-semibold mb-4">{position}</p>
        </div>
      </div>
      <div>
        <p className="text-lg">{comment}</p>
      </div>
    </div>
  </div>
);

const Recommendations = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextCard = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % recommendationsData.length
    );
  };

  const goToPrevCard = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + recommendationsData.length) %
        recommendationsData.length
    );
  };

  return (
    <div className="flex flex-col items-center overflow-hidden my-4">
      <motion.h1
        variants={fadeIn("up", 0.05)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5 }}
        className="text-3xl font-bold mb-0" 
      >
        Recommendations
      </motion.h1>

      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={currentIndex}
          variants={fadeIn('up', 0.2)}
          initial="hidden"
          animate="show"
          exit="hidden"
          className="relative flex items-center mb-0"
        >
          <div className="cursor-pointer text-4xl mr-5" onClick={goToPrevCard}>
            <GoChevronLeft />
          </div>
          <RecommendationCard {...recommendationsData[currentIndex]} />
          <div className="cursor-pointer text-4xl ml-5" onClick={goToNextCard}>
            <GoChevronRight />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Recommendations;