import React from "react";
import { IoIosContact } from "react-icons/io";
import Logo from "../assets/debaditya-som.png";

const Header = () => {
  return (
    <header className="py-8">
      <div className="container mx-auto mb-8">
        <div className="flex justify-between items-center">
          <img src={Logo} alt="" style={{ width: "20%", height: "auto" }} />
          <button className="btn btn-sm">
            <IoIosContact />
          </button>
        </div>
      </div>
    </header>
  );
};
export default Header;
