import React from 'react';
import pro1 from '../assets/project-1.jpg';
import pro2 from '../assets/pro-2.png';
import pro3 from '../assets/convene.png';
import pro4 from '../assets/LIFELINE-7g7PaU0K.png';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const projects = [
  {
    name: 'Convene',
    imageSrc: pro3,
    description: 'Convene seeks to create a streamlined ecosystem for participants and organizers, encouraging collaboration, engagement, and effective event management. ',
    techStack: ' React, FireBase',
  },
  {
    name: 'Culinova',
    imageSrc: pro1,
    description: 'Culinova is created with the intention to cater to individuals to bring out their culinary skills and experiment with try and tested recipes out there.',
    techStack: ' React, Node.js, MealDB',
  },

  {
    name: 'LifeLine',
    imageSrc: pro4,
    description: 'Lifeline is full-stack web application that uses location-based technology, particularly via a chatbot, to speed up the blood donation and receipt procedure in times of need',
    techStack: 'React.js, SpringBoot',
  },
];

const Project = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-16 mb-20" id='work'> 
      <motion.h1
        variants={fadeIn('up', 0.3)}
        initial="hidden"
        whileInView={'show'}
        viewport={{ once: false, amount: 0.7 }}
        className="text-3xl font-bold mb-9">
        My Projects
      </motion.h1>
      <div className="flex flex-wrap justify-center">
        {projects.map((project, index) => (
          <div
            key={index}
            className="max-w-xs rounded overflow-hidden shadow-md m-4 transition-transform transform hover:scale-105"
          >
            <img
              src={project.imageSrc}
              alt={project.name}
              className="w-full h-56 object-cover"
            />
            <div className="px-6 py-4">
              <div className="font-bold text-2xl mb-2">{project.name}</div>
              <p className="text-cyan-200 text-lg">{project.description}</p>
              <p className="text-cyan-50 text-base text-center font-bold mt-2">{project.techStack}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;