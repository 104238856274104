import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { fadeIn } from '../variants';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useInView } from 'react-intersection-observer';

const Services = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('show');
    }
  }, [controls, inView]);

  const educationData = [
    {
      institution: "Academy of Technology",
      degree: "BTech - Computer Science and Business System",
      date: " 2022 - 2026 ",
      grade:"Currently pursuing Computer Science with a focus on Business Systems in college, merging technical expertise with an understanding of business dynamics.",
    },
    {
      institution: "Abbot Shishu Hall",
      degree: "Sophomore",
      date: " 2020 - 2021 ",
      grade: "Class 12 graduate with a background in PCMB complemented by Computer Science, showcasing a fervent interest in exploring the dynamic landscape of technology and coding",
    },
    {
      institution: "Don Bosco School, Bandel",
      degree: "Freshman",
      date: " 2010 - 2020 ",
      grade: "Actively involved in diverse school activities, shown strong leadership, making significant contributions. Excelled in inter-school photography competition, securing second place twice. Additionally, participated in plays, chess, football, reflecting a well-rounded commitment to arts and sports in our school community.",
    },
  ];

  const experienceData = [
    {
      role: "Front-end Developer Intern",
      date: "May 2024 - June 2024",
      organization: "AuraX",
      responsibilities: "Implemented the visual and interactive aspects of a website, ensuring an optimal user experience through the use of tech stacks like Next.js, Tailwind CSS and Three.js",
    },
    {
      role: "Full stack developer intern",
      date: "Mar 2024- April 2024",
      organization: "Oblivion Technologies",
      responsibilities: "This experience allowed for the development of strong multitasking and project management skills while gaining valuable exposure to different industries.",
    },
    {
      role: "Cloud Computing Associate",
      date: "Aug 2023 - Present",
      organization: "Google DSC, AOT",
      responsibilities: "Organized skill-focused workshops and collaborating on impactful projects combining my tech passion with guiding peers toward meaningful cloud solutions.",
    },
  ];

  const communityData = [
    {
      organization: "Microsoft",
      date: "Oct 2023 - Present",
      role: "Beta LSA",
      responsibilities: "Learn Student Ambassador dedicated to helping fellow students and developing technical & career skills.",
    },
        {
      organization: "IEEE SB AOT",
      date: "Mar 2023 - Present",
      role: "Web Developer",
      responsibilities: "Student Branch of the world's largest technical professional organization dedicated to advancing technology.",
    },
    {
      organization: "CNCF Hooghly",
      date: "Aug 2023 - Mar 2024",
      role: "Core Organising Team",
      responsibilities: "Organized events reaching a total of 100+ devs",
    },
    {
      organization: "GeeksforGeeks",
      date: "Jul 2023 - Present",
      role: "Campus Representative",
      responsibilities: "Dedicated to promoting tech knowledge and growing campus tech community.",
    },
  ];

  const technicalSkillsData = [
    { skill: "AWS", percentage: 50 },
    { skill: "C/C++", percentage: 80 },
    { skill: "Content Writing", percentage: 60 },
    { skill: "CSS", percentage: 75 },
    { skill: "Docker", percentage: 70 },
    { skill: "Git", percentage: 70 },
    { skill: "HTML", percentage: 75 },
    { skill: "Java", percentage: 85 },
    { skill: "JavaScript", percentage: 80 },
    { skill: "Kubernetes", percentage: 65 },
    { skill: "Linux", percentage: 65 },
    { skill: "Mongo DB", percentage: 70 },
    { skill: "MySQL", percentage: 60 },
    { skill: "Next.js", percentage: 60 },
    { skill: "Node.js", percentage: 65 },
    { skill: "Python", percentage: 85 },
    { skill: "Postman", percentage: 88 },
    { skill: "React.js", percentage: 80 },
    { skill: "REST API", percentage: 75 },
    { skill: "Spring Boot", percentage: 80 },
    { skill: "SQL", percentage: 50 },
    { skill: "Tailwind CSS", percentage: 80 },
    { skill: "Typescript", percentage: 70 },
  ];

  return (
    <div id="Services" className="container mx-auto my-16 mb-20 flex flex-col items-center first-letter:justify-center overflow-hidden">
      <motion.h1
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.6 }}
        className="text-3xl font-bold mb-6"
        ref={ref}
      >
        Education and Experience
      </motion.h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8 mb-12">
        {/* Education Timeline */}
        <div className="lg:col-span-2 mb-8 lg:mb-20">
          <motion.h2
            variants={fadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="text-2xl font-bold mb-4 text-center"
          >
            Education
          </motion.h2>
          <VerticalTimeline>
            {educationData.map((edu, index) => (
              <VerticalTimelineElement
                key={index}
                date={edu.date}
                iconStyle={{
                  background: index === 0
                    ? 'linear-gradient(45deg, #001F3F, #003366)'
                    : index === 1
                      ? 'linear-gradient(45deg, #00274E, #004080)'
                      : 'linear-gradient(45deg, #003366, #00509E)',
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  marginLeft: '-15px',
                }}
                contentStyle={{
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '10px',
                  marginLeft: '4px',
                  padding: '20px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease-in-out',
                }}
                icon={null}>
                <div>
                  <h3 className="mb-2 text-lg font-semibold">{edu.institution}</h3>
                  <p className="mb-2 font-bold">{edu.degree}</p>
                  <p>{edu.grade}</p>
                </div>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
        {/* Experience */}
        <div className="lg:col-span-2 mb-8 lg:mb-20">
      
          <motion.h2
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
          className="text-2xl font-bold mb-4 text-center">Experience</motion.h2>
          <VerticalTimeline>
            {experienceData.map((exp, index) => (
              <VerticalTimelineElement
                key={index}
                date={exp.date}
                iconStyle={{
                  background: index === 0
                    ? 'linear-gradient(45deg, #001F3F, #003366)'
                    : index === 1
                      ? 'linear-gradient(45deg, #00274E, #004080)'
                      : 'linear-gradient(45deg, #003366, #00509E)',
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  marginLeft: '-15px',
                }}
                contentStyle={{
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '10px',
                  marginLeft: '4px',
                  padding: '20px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease-in-out',
                }}
                icon={null}
              >
                <div>
                  <h3 className="mb-2 text-lg font-semibold">{exp.role}</h3>
                  <p className="mb-2 font-bold">{exp.organization}</p>
                  <p>{exp.responsibilities}</p>
                </div>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>

        {/* Community Engagement */}
        <div className="lg:col-span-1 p-4">
          <motion.h2
          variants={fadeIn("right", 0.1)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.2 }}
          className="text-2xl mb-4">Community Engagement</motion.h2>
            {communityData.map((community, index) => (
  <div key={index} className="mb-6 bg-gray-900 bg-opacity-70 rounded-md p-4 community-card">
    <p className="mb-2 font-extrabold" style={{ textShadow: '0 0 5px rgba(0, 0, 255, 1)' }}>{community.organization}</p>
    <p className="mb-2" style={{ fontSize: '0.9rem' }}>{community.date} | {community.role}</p>
    <p>{community.responsibilities}</p>
  </div>
))}

        </div>

        {/* Technical Skills */}
        <div className="lg:col-span-1">
          <motion.h2 
          variants={fadeIn("left", 0.1)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.2 }}
          className="text-2xl mb-6">Technical Skills</motion.h2>
          <ul className="flex flex-col items-start">
            {technicalSkillsData.map((skill, index) => (
              <motion.li
                key={index}
                initial={{ width: 0 }}
                animate={{ width: `${skill.percentage}%` }}
                transition={{ duration: 1, delay: 0.3 }}
                whileInView={"show"}
                viewport={{ once: false}}
                className="mb-3 flex items-center"
              >
                <span className="text-sm font-semibold" style={{ width: '5rem' }}>{skill.skill}</span>
                <div className="flex-grow h-2 bg-gradient-to-r from-blue-900 to-cyan-500 mx-3 relative rounded" style={{ width: `${skill.percentage * 0.1}%` }}></div>
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
