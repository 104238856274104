import React from 'react';
import { FaLinkedin, FaPhotoVideo } from 'react-icons/fa';
import { MdDesignServices, MdWeb } from 'react-icons/md';
import { FaCloud, FaCode, FaBusinessTime } from 'react-icons/fa6';
import { VscAzureDevops } from "react-icons/vsc";
import { PiMicrophoneStage } from "react-icons/pi";
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import image1 from '../assets/skill-1.jpg';
import image2 from '../assets/skill-2.png';
import image3 from '../assets/skill-3.jpg';
import image4 from '../assets/skill-cloud.jpg';
import image5 from '../assets/skill-photo.avif';
import image9 from '../assets/skill-LI.avif';
import image6 from '../assets/skill-mar.jpg';
import imageui from '../assets/skill-ui.jpg';
import webdev from '../assets/skill-4.jpg';

function FrontOfCard({ title, image }) {
  const backgroundImageStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  
  return (
    <div className="relative w-full h-full ">
      <div className="absolute inset-0 w-full h-full flex flex-col items-center justify-center transition-all duration-100 delay-200 z-20 hover:opacity-0" style={backgroundImageStyle}>
        <div className="absolute inset-0 w-full h-full" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}></div>
        <div className="text-center bg-black p-4 md:p-8 lg:p-16 relative z-10 h-full flex flex-col justify-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', width: '100%' }}>
          <h3 className="text-xl md:text-2xl lg:text-3xl font-extrabold text-white mb-2 leading-tight">{title}</h3>
        </div>
      </div>
      <div className="absolute inset-0 w-full h-full bg-gradient-to-b from-transparent to-black"></div>
    </div>
  );
}

function BackOfCard({ data, icon }) {
  return (
    <div className="absolute inset-0 w-full h-full flex flex-col items-center justify-center text-center bg-cyan-600 transition-all transform z-10 card-back">
      {icon && React.createElement(icon, { size: 32, className: 'mb-2 text-white' })}
      <div className="p-4 sm:p-8 lg:p-16 h-full flex flex-col justify-center">
        <p style={{ fontSize: '0.9rem' }} className="leading-tight text-sm sm:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
          {data}
        </p>
      </div>
    </div>
  );
}


function ServicesCard({ title, data, icon, image }) {
  return (
    <div className="relative w-48 h-32 md:w-80 md:h-48 rounded-xl text-white text-justify overflow-hidden cursor-pointer transition-all duration-700 card" >
      <FrontOfCard title={title} image={image} />
      <BackOfCard data={data} icon={icon} />
    </div>
  );
}

const About = () => {
  const cardData = [
    { title: 'Web Development', data: 'Skilled in creating dynamic and responsive websites using the MERN stack, emphasizing functionality and user experience.', icon: MdWeb, image: webdev },
    { title: 'UI/UX Designing', data: 'Proficient in crafting visually appealing and user-friendly interfaces for an enhanced experience.', icon: MdDesignServices, image: imageui },
    { title: 'Cloud Computing', data: 'Skilled in deploying and managing applications on AWS, Azure, and Google Cloud to optimize scalability and performance.', icon: FaCloud, image: image4 },
    { title: 'DevOps', data: 'Experienced in implementing DevOps practices with tools like Jenkins and Docker for efficient development and deployment. ', icon: VscAzureDevops, image: image3 },
    { title: 'Public Speaking', data: 'Hosted several events including webinars, workshops and seminars', icon: PiMicrophoneStage, image: image1 },
    { title: 'Marketing', data: 'Well-versed in diverse marketing strategies, including digital marketing and SEO, for effective promotion across various platforms and mediums.', icon: FaBusinessTime, image: image6 },
    { title: 'Photography and Editing', data: 'Passionate about bringing a creative perspective through visual storytelling and attention to detail.', icon: FaPhotoVideo, image: image5 },
    { title: 'Coding', data: 'Proficient in languages like Java, C, C++ ensuring clean and maintainable code for any robust applications.', icon: FaCode, image: image2 },
    { title: "There's more..", data: 'For a more detailed overview of my skills and projects, check out my LinkedIn profile', icon: FaLinkedin, image: image9 },
  ];

  return (
    <div id="about" className="flex flex-col items-center mt-8 mb-8">
      <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className="text-3xl md:text-4xl lg:text-5xl font-bold mb-9">
        Skills
      </motion.h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
        {cardData.map((card, index) => (
          <ServicesCard key={index} title={card.title} data={card.data} icon={card.icon} image={card.image} />
        ))}
      </div>
    </div>
  );
}

export default About;