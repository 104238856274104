import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Contact = () => {
  return (
    <div className="flex items-center justify-center h-screen" id='contact'>
      <motion.div initial="hidden" animate="visible" variants={fadeIn} className="text-center">
        <h4 
       
        className='text-2xl uppercase text-cyan-400 font-medium mb-2 tracking-wide'>Get in touch</h4>
        <h2 
         className='text-[35px] lg:text-[90px] leading-none mb-12'>Let's Connect</h2>
        <form className="max-w-md mx-auto">
          <motion.label htmlFor="name" className='block text-left text-white mb-2' variants={fadeIn}>
            Name:
            <motion.input
              type="text"
              id="name"
              name="name"
              className='w-full bg-transparent border-b py-3 outline-none placeholder:text-white focus:border-cyan-500 transition-all'
              variants={fadeIn}
            />
          </motion.label>

          <motion.label htmlFor="email" className='block text-left text-white mb-2' variants={fadeIn}>
            Email:
            <motion.input
              type="email"
              id="email"
              name="email"
              className='w-full bg-transparent border-b py-3 outline-none placeholder:text-white focus:border-cyan-500 transition-all'
              variants={fadeIn}
            />
          </motion.label>

          <motion.label htmlFor="message" className='block text-left text-white mb-2' variants={fadeIn}>
            Message:
            <motion.textarea
              id="message"
              name="message"
              className='w-full bg-transparent border-b py-3 outline-none placeholder:text-white focus:border-cyan-500 transition-all'
              variants={fadeIn}
            />
          </motion.label>

          <motion.button
            type="submit"
            className='bg-cyan-500 text-white py-3 px-6 mt-4 rounded-full transition-all hover:bg-cyan-950'
            variants={fadeIn}
          >
            Submit
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default Contact;
